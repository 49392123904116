import React from "react";
import { connect, useDispatch } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_PRICING_EMAIL_MODAL } from "components/modals";
import { deleteAuctionActivityFile, hideModal, sendEmailNotification } from "actions";
import { Formik } from "formik";
import { EditorField, FormCollapsible, SubmitButton, TextInput } from "components/forms";
import { Form } from "react-bootstrap";
import { activeCaseSelector, emailTemplateSelector, insuredListSelector, managementChoicesSelector } from "reducers";
import * as notifications from "../../../../notifications";
import { findObjectByPropertyValue, moneyDisplay } from "../../../../utils";
import { prepareTags } from "./tags";

const SendPricingEmailModal = ({ ...props }) => {
  if (!props.caseInfo) {
    return;
  }
  const dispatch = useDispatch();

  // Tag replacements
  let subject = props.email_template?.subject || "";
  subject = subject.replace("{Application ID}", props.caseInfo.submission_id);
  let body = props.email_template?.html || "";
  let { insured_names, insured_ages, insured_gender } = prepareTags(props.insuredInfo);
  body = body.replace("{Insured Names}", insured_names);
  body = body.replace("{Insured Ages}", insured_ages);
  body = body.replace("{Insured Gender}", insured_gender);
  body = body.replace("{Face Amount}", moneyDisplay(props.caseInfo.face_amount));
  body = body.replace("{Product Type}", props.caseInfo.product_type);

  return (
    <FullPageModal modalType={SEND_PRICING_EMAIL_MODAL} title={`Send Pricing Manager Email`}>
      <Formik
        enableReinitialize
        initialValues={{
          _to: props.pricingManagerEmail,
          subject,
          body,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const response = await props.sendEmailNotification(values);
          dispatch(hideModal(SEND_PRICING_EMAIL_MODAL));
          if (response && response.status === 500) {
            notifications.error("Error sending email");
          } else {
            notifications.success("Email sent");
            dispatch(hideModal(SEND_PRICING_EMAIL_MODAL));
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <>
              <FormCollapsible onSubmit={handleSubmit} title="Send Email">
                <Form.Row>
                  <TextInput label="To" name="_to" as="textarea" rows={2} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="CC" name="cc" placeholder="Enter CC Email(s)" />
                  <TextInput label="BCC" name="bcc" placeholder="Enter BCC Email(s)" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Subject" name="subject" />
                </Form.Row>
                <Form.Row>
                  <EditorField label={props.email_template?.name} name="body" isRequired />
                </Form.Row>
                <SubmitButton defaultText={"Send"} />
              </FormCollapsible>
            </>
          );
        }}
      </Formik>
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const caseInfo = activeCaseSelector(state);

  // Find Pricing Manager email
  const pricingManagerId = caseInfo.pricing_manager;
  const choices = managementChoicesSelector(state);
  const salesRepChoices = choices.case_sales_representatives.getChoices();
  const pricingManagerData = salesRepChoices.find(salesRep => salesRep.value === pricingManagerId);
  let pricingManagerEmail = "";
  if (pricingManagerData?.email) {
    pricingManagerEmail = `${pricingManagerData?.label} <${pricingManagerData?.email}>; `;
  }

  // Find Pricing Email
  const emailTemplates = emailTemplateSelector(state);
  const pricingEmailName = "Pricing Team File Building Notification";
  const emailTemplate = findObjectByPropertyValue(emailTemplates, "name", pricingEmailName);

  return {
    caseInfo,
    email_template: emailTemplate,
    insuredInfo: insuredListSelector(state),
    pricingManagerEmail,
  };
};

export default connect(mapStateToProps, {
  deleteAuctionActivityFile,
  sendEmailNotification,
})(SendPricingEmailModal);
