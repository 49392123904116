import React from "react";
import { connect } from "react-redux";

import {
  createEmptyCase,
  setActiveTab,
  initPagination,
  fetchLifeExpectancyChoices,
  clearCasesData,
  clearCaseFiles,
  clearActiveCaseFile,
  cleanLifeSettlementCaseData,
  cleanRecords,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  fetchRecords,
} from "actions";
import RecordsFilterForm from "./RecordsFilterForm";
import RecordsTable from "./RecordsTable";
import RecordsSummaryForm from "./RecordsSummaryForm";
import RecordsPageHeader from "./RecordsPageHeader";
import { TaskTab } from "./constants";
import { FETCH_RECORDS } from "../../actions/types";
import { STATUS } from "../cases/caseFiles/constants";

const RecordsPage = ({ type = TaskTab.ALL, setActiveTab, ...props }) => {
  const [activeSection, setActiveSection] = React.useState("tasks");
  React.useEffect(() => {
    props.cleanRecords();
    props.initPagination();
    props.fetchLifeExpectancyChoices();
    props.clearCasesData();
    props.clearCaseFiles();
    props.clearActiveCaseFile();
    props.cleanLifeSettlementCaseData();
    props.fetchManagerAgents();
    props.fetchLifeSettlementChoices();
    const initialFilters = {
      record_owner: props.record_owner,
      status: STATUS.PENDING,
      stage: "Opportunity,Price Discovery,In Closing,Asset",
    };
    props.fetchTasks(initialFilters, FETCH_RECORDS);
    document.title = "LifeRoc | Tasks";
  }, []);

  console.log(activeSection);

  return (
    <div>
      <RecordsPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        {type === TaskTab.ALL && <RecordsFilterForm setActiveSection={setActiveSection} type={type} />}
        {type === TaskTab.CASE && <RecordsFilterForm setActiveSection={setActiveSection} type={type} />}
        {type === TaskTab.CASE_REQUIREMENT && <RecordsFilterForm setActiveSection={setActiveSection} type={type} />}
        {type === TaskTab.ACCOUNT && <RecordsFilterForm setActiveSection={setActiveSection} type={type} />}
        {type === TaskTab.LEAD && <RecordsFilterForm setActiveSection={setActiveSection} type={type} />}
        <RecordsSummaryForm type={type} />
        <RecordsTable type={type} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    record_owner: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  createEmptyCase,
  setActiveTab,
  initPagination,
  fetchLifeExpectancyChoices,
  clearCasesData,
  clearCaseFiles,
  clearActiveCaseFile,
  cleanLifeSettlementCaseData,
  cleanRecords,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  fetchTasks: fetchRecords,
})(RecordsPage);
