import {
  EDIT_ACCOUNT_TASK,
  EDIT_CASE_FILE,
  EDIT_CASE_TASK,
  EDIT_LEAD_TASK,
  EDIT_LIFE_SETTLEMENT_CASE,
  FETCH_RECORDS,
  FETCH_CASE_RECORDS,
  FETCH_CASE_REQUIREMENT_RECORDS,
  FETCH_ACCOUNT_RECORDS,
  FETCH_LEAD_RECORDS,
  SELECT_RECORD_ID,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  records: [],
  case_records: [],
  case_requirement_records: [],
  account_records: [],
  lead_records: [],
  activeRecordId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_RECORDS:
      return {
        ...state,
        records: arrayToObjectIndexedById(action.payload),
      };
    case FETCH_CASE_RECORDS:
      return {
        ...state,
        case_records: arrayToObjectIndexedById(action.payload),
      };
    case FETCH_CASE_REQUIREMENT_RECORDS:
      return {
        ...state,
        case_requirement_records: arrayToObjectIndexedById(action.payload),
      };
    case FETCH_ACCOUNT_RECORDS:
      return {
        ...state,
        account_records: arrayToObjectIndexedById(action.payload),
      };
    case FETCH_LEAD_RECORDS:
      return {
        ...state,
        lead_records: arrayToObjectIndexedById(action.payload),
      };

    case SELECT_RECORD_ID:
      return { ...state, activeRecordId: action.payload };

    case EDIT_LIFE_SETTLEMENT_CASE: {
      if (action.payload.status) {
        for (let record of state.records) {
          if (record.case_id === action.payload.id) {
            record.status = action.payload.status;
            record.status_name = action.payload.status_name;
          }
        }
      }
      return state;
    }
    case EDIT_ACCOUNT_TASK: {
      if (!state.activeRecordId) {
        return state;
      }
      return {
        ...state,
        records: {
          ...state.records,
          [state.activeRecordId]: {
            ...state.records[state.activeRecordId],
            record_type: "Account",
            task_type: `${action.payload.task_type} / ${action.payload.subject}`,
            record_owner: action.payload.owner_name,
            record_creator: action.payload.created_by,
            last_editor: action.payload.last_edited_by_name,
            last_edit_date: action.payload.last_edit_date,
            due_date: action.payload.due_date,
          },
        },
      };
    }
    case EDIT_LEAD_TASK: {
      if (!state.activeRecordId) {
        return state;
      }
      return {
        ...state,
        records: {
          ...state.records,
          [state.activeRecordId]: {
            ...state.records[state.activeRecordId],
            record_type: "Lead",
            record_name: action.payload.subject,
            task_type: `${action.payload.task_type} / ${action.payload.subject}`,
            record_owner: action.payload.owner_name,
            record_creator: action.payload.created_by,
            last_editor: action.payload.last_edited_by_name,
            last_edit_date: action.payload.last_edit_date,
            due_date: action.payload.due_date,
          },
        },
      };
    }
    case EDIT_CASE_FILE: {
      if (!state.activeRecordId) {
        return state;
      }
      return {
        ...state,
        records: {
          ...state.records,
          [state.activeRecordId]: {
            ...state.records[state.activeRecordId],
            ...action.payload,
            id: state.activeRecordId,
            record_type: "Case Requirement",
            task_type: `${action.payload.category} / ${action.payload.sub_category} / ${action.payload.description}`,
            // change this owner
            record_owner: action.payload.responsible_name,
            record_creator: action.payload.created_by_name,
            last_editor: action.payload.last_edited_by_name,
            last_edit_date: action.payload.last_edit_date,
            due_date: action.payload.follow_up_date,
          },
        },
      };
    }
    case EDIT_CASE_TASK: {
      if (!state.activeRecordId) {
        return state;
      }
      return {
        ...state,
        records: {
          ...state.records,
          [state.activeRecordId]: {
            ...state.records[state.activeRecordId],
            record_type: "Case Task",
            record_name: action.payload.record_name,
            record_owner: action.payload.record_owner,
            record_creator: action.payload.record_creator,
            last_editor: action.payload.last_editor,
            last_edit_date: action.payload.last_edit_date,
            due_date: action.payload.due_date,
          },
        },
      };
    }
    default:
      return state;
  }
};

// Selectors
export const recordsSelector = state => {
  if (state.records) {
    return Object.values(state.records);
  } else {
    return [];
  }
};

export const caseRecordsSelector = state => {
  if (state.case_records) {
    return Object.values(state.case_records);
  } else {
    return [];
  }
};

export const caseRequirementRecordsSelector = state => {
  if (state.case_requirement_records) {
    return Object.values(state.case_requirement_records);
  } else {
    return [];
  }
};

export const accountRecordsSelector = state => {
  if (state.account_records) {
    return Object.values(state.account_records);
  } else {
    return [];
  }
};

export const leadRecordsSelector = state => {
  if (state.lead_records) {
    return Object.values(state.lead_records);
  } else {
    return [];
  }
};
