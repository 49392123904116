import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../notifications";
import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
} from "components/forms";
import { createLeadTask, editLeadTask, fetchManagerAgents, fetchRecords, hideModal } from "actions";
import {
  activeLeadIdSelector,
  activeLeadTaskSelector,
  activeTabSelector,
  storedRecordsFiltersSelector,
  storedLeadRecordsFiltersSelector,
  userChoicesSelector,
} from "reducers";
import { partial } from "../../../utils";
import { TASK_STATUS, TASK_TYPES } from "../../accounts/constants";
import { addThreeBusinessDays } from "../../../reducers/utils";
import { useLocation } from "react-router-dom";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "../../records/constants";
import { URLS } from "../../../constants";
import { LEAD_TASK_MODAL } from "../../../components/modals";

const LeadTaskForm = ({ activeTask, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
  }, []);
  let isSaved = activeTask?.id;
  let title = isSaved ? "Edit Lead Task" : "New Lead Task";
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...activeTask,
        owner: isEmpty(activeTask) ? props.userId : activeTask.owner,
        due_date: isEmpty(activeTask) ? props.defaultDueDate : activeTask.due_date,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeTask) ? props.createLeadTask : partial(props.editLeadTask, activeTask.id);

        values.lead_id = props.activeLeadId;

        try {
          await method(values);
          notifications.clearAll();
          notifications.success("Lead Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Task");
        }
        // Refresh tasks table - depends if we are in All Tasks or Lead Tasks
        if (props.activeTab === "tasks") {
          let type = TaskTab.ALL;
          let filterValues = props.recordsFilters;
          if (location.pathname.includes(URLS.LEAD_TASKS)) {
            type = TaskTab.LEAD;
            filterValues = props.leadRecordsFilters;
          }
          const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
          console.log(filterValues, fetch_action);
          await props.fetchTasks({ ...filterValues }, fetch_action);
        }
        dispatch(hideModal(LEAD_TASK_MODAL));
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={title}>
            {values.record_id ? (
              <>
                <Form.Row>
                  <TextInput label="Phone" name="phone_number" md={3} disabled />
                  <TextInput label="Other Phone" name="other_phone" md={3} disabled />
                  <TextInput label="Email" name="email" disabled />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Record ID" name="record_id" disabled />
                  <TextInput label="Record Name" name="record_name" disabled />
                </Form.Row>
              </>
            ) : null}
            <Form.Row>
              <Select label="Type" name="type" placeholder="Select Type" options={TASK_TYPES} />
              <Select label="Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
              <Select label="Owner" name="owner" placeholder="Select Owner" options={props.userChoices} />
              <DateInput label="Due Date" name="due_date" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Task Creator" name="created_by" disabled />
              <DateInput label="Create Date" name="date_created" disabled />
              <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const defaultDueDate = addThreeBusinessDays().toISOString().split("T")[0];
  return {
    activeLeadId: activeLeadIdSelector(state),
    activeTask: activeLeadTaskSelector(state) || {},
    userChoices,
    defaultDueDate,
    userId: state.auth.user_id,
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    leadRecordsFilters: storedLeadRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  createLeadTask,
  editLeadTask,
  fetchManagerAgents,
  fetchTasks: fetchRecords,
})(LeadTaskForm);
