import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  DateInput,
  DateTimeInput,
  EditorField,
  Select,
  StateSelect,
  SubmitButton,
  TextInput,
} from "components/forms";
import { activeAccountSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { isAuthorized, partial } from "../../../utils";
import {
  createEmptyEntityAccountForAccount,
  editAccount,
  unsetShouldSave,
  showModal,
  selectManageEntity,
  selectAccountTask,
  selectAccountAffiliation,
} from "actions";
import AccountTaskTable from "./tasks/AccountTaskTable";
import * as notifications from "../../../notifications";
import {
  ACCOUNT_TYPES,
  LINES_OF_BUSINESS,
  MARKETING_SOURCES,
  PRACTICE_TYPES,
  SOURCE_OF_ACCOUNTS,
  YES_NO_PAST,
} from "../constants";
import { FaEdit, FaPlus } from "react-icons/fa";
import { PERMISSIONS } from "../../../constants";
import {
  ACCOUNT_AFFILIATION_MODAL,
  ACCOUNT_TASK_MODAL,
  CREATE_ACCOUNT_MODAL,
  MANAGE_ENTITY_MODAL,
} from "../../../components/modals";
import { findAccountTypeFromLabel, getEntityIdFromAccount } from "../utils";
import { RecordsTable } from "../../../components/table";
import AccountAffiliationTable from "./affiliations/AccountAffiliationTable";
import TeamMemberTable from "./teamMembers/TeamMemberTable";
import EngagementActivitiesTable from "./engagementActivities/EngagementActivitiesTable";

const AccountForm = ({
  accountInfo,
  entityType,
  entityId,
  individualType,
  activeHierarchySalesRepName,
  availableEntitiesForUser,
  hasAdminPermission,
  ...props
}) => {
  if (!accountInfo) {
    // Still didn't fetch data
    return null;
  }

  const submitRef = React.useRef();
  let entitiesListOptions = availableEntitiesForUser;

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (accountInfo.associated_entity && accountInfo.associated_entity_name) {
      const associatedEntityChoice = {
        label: accountInfo.associated_entity_name,
        value: accountInfo.associated_entity,
      };
      if (!availableEntitiesForUser.some(e => e.value === accountInfo.associated_entity)) {
        // if the user has no access to that entity, add it as an option so it can be seen in the Select
        // adds to entitiesListOptions so after changing it, doesn't lose previous choice
        entitiesListOptions = [...entitiesListOptions, associatedEntityChoice];
      }
    }
  }, [accountInfo]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...accountInfo,
        activeHierarchySalesRepName,
        availableEntitiesForUser,
        entitiesListOptions,
        associated_entity: accountInfo.associated_entity ? accountInfo.associated_entity : null,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const method = partial(props.editAccount, accountInfo.id);
        delete values.active_campaign_contact_id;
        delete values.active_campaign_list_id;
        delete values.active_campaign_list;
        delete values.active_campaign_error;

        try {
          await method(values);
          notifications.success("Account saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Account");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const renderEntityButtons = () => {
          let buttons = [];
          // If an Associated Entity is selected, show Manage Entity button
          if (
            values.associated_entity &&
            typeof values.associated_entity === "number" &&
            availableEntitiesForUser.some(e => e.value === values.associated_entity)
          ) {
            buttons.push(
              <button
                className={`btn btn-sm btn--secondary`}
                style={{
                  float: "right",
                  fontSize: 12,
                  padding: "2px 5px",
                }}
                type="button"
                onClick={() => {
                  props.selectManageEntity(values.associated_entity);
                  props.showModal(MANAGE_ENTITY_MODAL, { accountType: entityType });
                }}
              >
                <FaEdit />
                Manage Entity
              </button>,
            );
          }
          // If Account is type Individual, show Add Entity button
          if (values.type === 0 && hasAdminPermission) {
            buttons.push(
              <button
                className={`btn btn-sm btn--secondary`}
                style={{
                  float: "right",
                  fontSize: 12,
                  padding: "2px 5px",
                  marginRight: "5px",
                }}
                type="button"
                onClick={() => {
                  props.showModal(CREATE_ACCOUNT_MODAL, { accountType: entityType });
                }}
              >
                <FaPlus />
                Add Entity
              </button>,
            );
          }
          return <>{buttons}</>;
        };

        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Collapsible title="Detail">
              <Form.Row>
                <Select
                  label="Account Type"
                  name="type"
                  placeholder="Select Account Type"
                  options={ACCOUNT_TYPES}
                  md={4}
                />
                <TextInput label="Account Name" name="source_name" disabled />
                {values.type === 0 ? (
                  accountInfo && (
                    <Select
                      label="Entity Name"
                      name="associated_entity"
                      placeholder="Select Entity"
                      options={entitiesListOptions}
                      append={renderEntityButtons()}
                    />
                  )
                ) : (
                  <TextInput label="Entity Name" name="entity_name" />
                )}
              </Form.Row>
              <Form.Row>
                <TextInput label="Account ID" name="system_id" disabled />
                <TextInput label="National Producer Number" name="national_producer_number" disabled />
                <TextInput label="Sales Representative" name="activeHierarchySalesRepName" disabled />
              </Form.Row>
              {values.type === 0 ? (
                <>
                  <Form.Row>
                    <TextInput label="First Name" name="first_name" />
                    <TextInput label="Middle Name" name="middle_name" />
                    <TextInput label="Last Name" name="last_name" />
                  </Form.Row>
                  <Form.Row>
                    <TextInput label="Suffix" name="suffix" md={2} />
                    <TextInput label="Nick Name" name="nick_name" md={3} />
                    <TextInput label="Title" name="title" md={3} />
                    <DateInput label="Date of Birth" name="date_of_birth" disabled md={4} />
                  </Form.Row>
                </>
              ) : null}
              <Form.Row>
                <TextInput label="Work Phone" name="work_phone" />
                <TextInput label="Cell Phone" name="cell_phone" />
                <TextInput label="Other Phone" name="other_phone" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Work Extension" name="work_extension" />
                <TextInput label="Fax Number" name="fax" />
                <TextInput label="Website" name="website" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Work Email" lowercase={true} name="work_email" />
                <TextInput label="Other Email" lowercase={true} name="other_email" />
                <TextInput label="Case Status Email" lowercase={true} name="email" />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Work Email Opt Out" name="email_opt_out" md={4} />
                <BooleanSelect label="Other Email Opt Out" name="other_email_opt_out" md={4} />
                <BooleanSelect label="Case Email Opt Out" name="case_email_opt_out" md={4} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" md={12} />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" />
                <StateSelect label="State" name="state" placeholder="Select State" />
                <TextInput label="Zip Code" name="zipcode" />
              </Form.Row>
              <Form.Row>
                <TextInput label="LinkedIn" name="linkedin" />
                <TextInput label="Study Groups" name="study_groups" />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Collapsible>
            <RecordsTable
              title="Tasks Detail"
              createButtonLabel="Add Task"
              onCreateClick={e => {
                e.preventDefault();
                props.selectAccountTask(null);
                props.showModal(ACCOUNT_TASK_MODAL);
              }}
              Table={AccountTaskTable}
              darker={true}
            />
            <Collapsible title="Practice Profile">
              <Form.Row>
                <Select
                  label="Practice Type"
                  name="practice_type"
                  placeholder="Select Practice Type"
                  options={PRACTICE_TYPES}
                />
                <Select
                  label="Main Line of Business"
                  name="main_line_of_business"
                  placeholder="Select Main Line of Business"
                  options={LINES_OF_BUSINESS}
                />
                <Select label="Accounting" name="accounting" placeholder="Select Accounting" options={YES_NO_PAST} />
                <Select label="Annuities" name="annuities" placeholder="Select Annuities" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select label="Banking" name="banking" placeholder="Select Banking" options={YES_NO_PAST} />
                <Select
                  label="Disability Insurance"
                  name="disability_insurance"
                  placeholder="Select Disability Insurance"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Health Insurance"
                  name="health_insurance"
                  placeholder="Select Health Insurance"
                  options={YES_NO_PAST}
                />
                <Select label="Legal" name="legal" placeholder="Select Legal" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Life Insurance"
                  name="life_insurance"
                  placeholder="Select Life Insurance"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Life Settlements"
                  name="life_settlements"
                  placeholder="Select Life Settlements"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Premium Finance"
                  name="premium_finance"
                  placeholder="Select Premium Finance"
                  options={YES_NO_PAST}
                />
                <Select label="Medicare" name="medicare" placeholder="Select Medicare" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Property and Casualty"
                  name="property_and_casualty"
                  placeholder="Select Property and Casualty"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Registered Investment Advice"
                  name="registered_investment_advice"
                  placeholder="Select Registered Investment Advice"
                  options={YES_NO_PAST}
                  md={3}
                />
                <Select
                  label="Securities"
                  name="securities"
                  placeholder="Select Securities"
                  options={YES_NO_PAST}
                  md={3}
                />
                <Select
                  label="Trust Services"
                  name="trust_services"
                  placeholder="Select Trust Services"
                  options={YES_NO_PAST}
                  md={3}
                />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Designations">
              <Form.Row>
                <Select label="CFP" name="CFP" placeholder="Select CFP" options={YES_NO_PAST} />
                <Select label="CHFC" name="CHFC" placeholder="Select CHFC" options={YES_NO_PAST} />
                <Select label="CLU" name="CLU" placeholder="Select CLU" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select label="LUTCF" name="LUTCF" placeholder="Select LUTCF" options={YES_NO_PAST} md={4} />
                <Select label="MDRT" name="MDRT" placeholder="Select MDRT" options={YES_NO_PAST} md={4} />
              </Form.Row>
            </Collapsible>
            <RecordsTable
              title="Affiliations"
              createButtonLabel="Add Affiliation"
              onCreateClick={() => {
                props.selectAccountAffiliation(null);
                props.showModal(ACCOUNT_AFFILIATION_MODAL);
              }}
              Table={AccountAffiliationTable}
              darker={true}
            />
            <RecordsTable
              title="Team Members"
              createButtonLabel="Add Team Member"
              onCreateClick={() => {
                props.showModal(CREATE_ACCOUNT_MODAL, { accountType: individualType, entityId });
              }}
              buttonDisabled={!entityId}
              Table={TeamMemberTable}
              darker={true}
            />
            <Collapsible title="Qualitative Insight">
              <Form.Row>
                <Select
                  label="Data Source"
                  name="source_of_account"
                  placeholder="Select Data Source"
                  options={SOURCE_OF_ACCOUNTS}
                  disabled={!hasAdminPermission}
                  md={4}
                />
                <Select
                  label="Marketing Source"
                  name="marketing_source"
                  placeholder="Select Marketing Source"
                  options={MARKETING_SOURCES}
                  md={4}
                />
              </Form.Row>
              <Form.Row>
                <TextInput
                  label="Last Marketing Engagement"
                  name="last_marketing_engagement"
                  placeholder="TBD"
                  disabled
                />
                <TextInput label="Last Case Submit" name="last_case_submit" disabled />
                <TextInput label="Last Case Paid" name="last_case_paid" disabled />
              </Form.Row>
              <Form.Row>
                <DateInput label="Date Account Created" name="date_created" disabled />
                <DateTimeInput label="Date Account Last Modified" name="last_edit_date" disabled />
                <TextInput label="Last Modified By" name="last_edited_by_name" disabled />
              </Form.Row>
            </Collapsible>
            <RecordsTable
              title="Engagement Activity"
              actionButtons={[]}
              Table={EngagementActivitiesTable}
              darker={true}
            />
            <Collapsible title="Active Campaign">
              <Form.Row>
                <TextInput label="Contact" name="active_campaign_contact_id" disabled />
                <TextInput label="Subscribed to List" name="_active_campaign_list" disabled />
              </Form.Row>
              {values.active_campaign_error && (
                <Form.Row>
                  <TextInput label="Error" name="active_campaign_error" disabled />
                </Form.Row>
              )}
            </Collapsible>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  const accountInfo = activeAccountSelector(state);

  return {
    accountInfo,
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    entityId: accountInfo ? getEntityIdFromAccount(accountInfo) : null,
    entityType: findAccountTypeFromLabel("Entity"),
    individualType: findAccountTypeFromLabel("Individual"),
  };
};

export default connect(mapStateToProps, {
  editAccount,
  unsetShouldSave,
  createEmptyEntityAccountForAccount,
  showModal,
  selectManageEntity,
  selectAccountTask,
  selectAccountAffiliation,
})(AccountForm);
