import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { SubmitButton, TextInput } from "components/forms";
import { activeStateSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editState, unsetShouldSave, selectSynonym, showModal } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";

const StateDetailForm = ({ stateInfo, ...props }) => {
  if (!stateInfo) {
    // Still didn't fetch data
    return null;
  }

  const initialValues = {
    name: stateInfo.name,
    code: stateInfo.code,
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method = partial(props.editState, stateInfo.id);

        try {
          await method(values);
          notifications.success("State saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Form.Row>
              <TextInput label="State Name" name="name" md={6} />
              <TextInput label="State Code" name="code" md={6} />
            </Form.Row>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    stateInfo: activeStateSelector(state),
  };
};

export default connect(mapStateToProps, {
  editState,
  unsetShouldSave,
  selectSynonym,
  showModal,
})(StateDetailForm);
