import { makeChoices } from "../funders/utils";

const recissionPeriodOptions = [
  "3 Days From Payment Receipt",
  "3 Days From Duly Executed Contracts",
  "15 Days From Payment Receipt",
  "15 Days from Duly Executed Contracts",
  "15 Days from Agreement Date",
  "30 Days From Payment Receipt",
  "30 Days from Duly Executed Contracts",
  "30 Days from Agreement Date",
  "60 Days From Payment Receipt",
  "60 Days from Duly Executed Contracts",
  "60 Days from Agreement Date",
];

export const brokerCompensationChoices = [
  { value: "NO NO DISCLOSURE REQUIRED", label: "No - No Disclosure Required" },
  { value: "YES BY PROVIDER TO SELLER", label: "Yes - By Provider to Seller" },
  { value: "YES BY BROKER TO SELLER AND PROVIDER", label: "Yes - By Broker to Seller & Provider" },
  { value: "YES BY BROKER TO SELLER", label: "Yes - By Broker to Seller" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerPlanOfOperationChoices = [
  { value: "SURETY BOND REQUIRED", label: "Surety Bond Required" },
  { value: "EO REQUIRED", label: "E&O Required" },
  { value: "SURETY BOND OR EO REQUIRED", label: "Surety Bond or E&O Required" },
  { value: "NO", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const policyIssueDateOptions = ["None", "24+ Months", "60+ Months", "TBD"];

export const paymentAdvanceChoices = [
  { value: "Yes - With Fee", label: "Yes - With Fee" },
  { value: "Yes - No Fee", label: "Yes - No Fee" },
  { value: "Filing Rejected", label: "Filing Rejected" },
  { value: "Not File", label: "Not File" },
  { value: "Pending", label: "Pending" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const minimumPricingChoices = [
  { value: "Yes - CSV & ADB Amounts", label: "Yes - CSV & ADB Amounts" },
  { value: "Yes - See Pricing Guideline", label: "Yes - See Pricing Guideline" },
  { value: "No", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const leDeterminationChoices = [
  { value: "Licensed LE Providers Only", label: "Licensed LE Providers Only" },
  { value: "No Requirement", label: "No Requirement" },
  { value: "Based on Relevant Data", label: "Based on Relevant Data" },
  { value: "TBD", label: "TBD" },
  { value: "NA", label: "N/A" },
];

export const YesNoNaTbdChoices = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const requirePreNoticeCarrierChoices = [
  { value: "Yes - Prior to Contracts Going Out", label: "Yes - Prior to Contracts Going Out" },
  { value: "No", label: "No" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const requireNoticeCarrierChoices = [
  { value: "Yes - 20 Days from Seller Signed Contract", label: "Yes - 20 Days from Seller Signed Contract" },
  { value: "Yes - 20 Days from Seller Signed All Docs", label: "Yes - 20 Days from Seller Signed All Docs" },
  { value: "Yes - 3 Days from Receipt of Required Items", label: "Yes - 3 Days from Receipt of Required Items" },
  { value: "Yes - 10 Days from Duly Executed Contract", label: "Yes - 10 Days from Duly Executed Contract" },
];

export const requireNoticeInsuredChoices = [
  { value: "Yes - 20 Days from Change Completion", label: "Yes - 20 Days from Change Completion" },
];

export const letterOfCompetencyChoices = [
  { value: "Yes - Required", label: "Yes - Required" },
  { value: "No - Not Requited", label: "No - Not Requited" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const disclosureToFunder = [
  { value: "Yes - With Contract", label: "Yes - With Contract" },
  { value: "Yes - Before Contract", label: "Yes - Before Contract" },
  { value: "Not Required", label: "Not Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const disclosureFormDelivery = [
  { value: "Yes - With Contract ", label: "Yes - With Contract " },
  { value: "Yes - Before Contract", label: "Yes - Before Contract" },
  { value: "Not Required", label: "Not Required" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const brokerCeChoices = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
  { value: "CONTINGENT REQUIREMENT", label: "Contingent Requirement" },
  { value: "NA", label: "N/A" },
  { value: "TBD", label: "TBD" },
];

export const providerDocumentTypeChoices = [
  { value: 0, label: "Provider Anti-Fraud Plan" },
  { value: 1, label: "Verification of Coverage" },
  { value: 2, label: "Insured Notice" },
  { value: 3, label: "Carrier Notice" },
  { value: 4, label: "Seller Notice" },
  { value: 5, label: "Seller Closing Packet" },
  { value: 6, label: "Insured Closing Packet" },
  { value: 7, label: "Pricing Guidelines" },
  { value: 8, label: "Sample State Closing Packet" },
  { value: 9, label: "Provider Anti-Fraud Checklist" },
];

export const brokerDocumentTypeChoices = [
  { value: 0, label: "Broker Anti-Fraud Plan" },
  { value: 1, label: "Broker & Seller Contract" },
  { value: 2, label: "Broker Compensation Disclosure" },
  { value: 3, label: "Broker Transactional Summary" },
  { value: 4, label: "Broker HIPAA" },
  { value: 5, label: "Broker Policy Authorization" },
  { value: 6, label: "Broker Information Sheet" },
];

export const documentStatusChoices = [
  { value: 0, label: "Draft" },
  { value: 1, label: "In Use" },
  { value: 2, label: "Retired" },
];

export const recissionPeriodChoices = makeChoices(recissionPeriodOptions);
export const policyIssueDateChoices = makeChoices(policyIssueDateOptions);
