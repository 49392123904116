import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { BooleanSelect, EditorField, Select, SubmitButton, TextInput } from "components/forms";
import { activeStateSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editState, unsetShouldSave, selectSynonym, showModal } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";
import {
  disclosureFormDelivery,
  leDeterminationChoices,
  letterOfCompetencyChoices,
  minimumPricingChoices,
  paymentAdvanceChoices,
  policyIssueDateChoices,
  recissionPeriodChoices,
  requireNoticeCarrierChoices,
  requireNoticeInsuredChoices,
  requirePreNoticeCarrierChoices,
  YesNoNaTbdChoices,
} from "./constants";

const StateDetailProviderForm = ({ stateInfo, ...props }) => {
  if (!stateInfo) {
    // Still didn't fetch data
    return null;
  }

  const initialValues = {
    state_regulations: stateInfo.state_regulations,
    state_statutes: stateInfo.state_statutes,
    recission_period: stateInfo.recission_period,
    policy_issue_date: stateInfo.policy_issue_date,
    payment_advance: stateInfo.payment_advance,
    letter_of_competency: stateInfo.letter_of_competency,
    minimum_pricing: stateInfo.minimum_pricing,
    premium_apportionment: stateInfo.premium_apportionment,
    rdb: stateInfo.rdb,
    provider_notes: stateInfo.provider_notes,
    disclosure_form_delivery: stateInfo.disclosure_form_delivery,
    le_determination: stateInfo.le_determination,
    require_pre_notice_carrier: stateInfo.require_pre_notice_carrier,
    disclosure_to_funder: stateInfo.disclosure_to_funder,
    require_notice_beneficiary: stateInfo.require_notice_beneficiary,
    require_notice_carrier: stateInfo.require_notice_carrier,
    require_notice_insured: stateInfo.require_notice_insured,
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method = partial(props.editState, stateInfo.id);

        try {
          await method(values);
          notifications.success("State saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Form.Row>
              <TextInput label="State Regulations" name="state_regulations" md={6} />
              <TextInput label="State Statutes" name="state_statutes" md={6} />
            </Form.Row>
            <Form.Row>
              <Select label="Recission Period" name="recission_period" options={recissionPeriodChoices} md={3} />
              <Select
                label="Policy Issue Date (S&C Provisions)"
                name="policy_issue_date"
                options={policyIssueDateChoices}
                md={3}
              />
              <Select label="Payment Advance" name="payment_advance" options={paymentAdvanceChoices} md={3} />
              <Select
                label="Letter of Competency"
                name="letter_of_competency"
                md={3}
                options={letterOfCompetencyChoices}
              />
            </Form.Row>
            <Form.Row>
              <Select label="Minimum Pricing" name="minimum_pricing" options={minimumPricingChoices} md={3} />
              <Select label="Premium Apportionment" name="premium_apportionment" options={YesNoNaTbdChoices} md={3} />
              <Select label="RDB" name="rdb" options={YesNoNaTbdChoices} md={3} />
              <Select
                label="Disclosure Form Delivery"
                name="disclosure_form_delivery"
                options={disclosureFormDelivery}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Life Expectancy Determination"
                name="le_determination"
                options={leDeterminationChoices}
                md={3}
              />
              <Select
                label="Require Pre-Notice - Carrier"
                name="require_pre_notice_carrier"
                options={requirePreNoticeCarrierChoices}
                md={3}
              />
              <Select label="Disclosure to Funder" name="disclosure_to_funder" options={YesNoNaTbdChoices} md={3} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Require Notice - Beneficiary" name="require_notice_beneficiary" md={3} />
              <Select
                label="Require Notice - Carrier"
                name="require_notice_carrier"
                options={requireNoticeCarrierChoices}
                md={3}
              />
              <Select
                label="Require Notice - Insured"
                name="require_notice_insured"
                options={requireNoticeInsuredChoices}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <EditorField label="Provider Compliance Notes" name="provider_notes" />
            </Form.Row>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    stateInfo: activeStateSelector(state),
  };
};

export default connect(mapStateToProps, {
  editState,
  unsetShouldSave,
  selectSynonym,
  showModal,
})(StateDetailProviderForm);
