import React, { useState } from "react";
import { connect } from "react-redux";
import { FaEdit } from "react-icons/fa";

import {
  clearActiveCaseFile,
  editCaseWithoutDispatch,
  fetchCaseFilesByCaseDocumentId,
  selectAccount,
  selectAccountTask,
  selectActiveRecordId,
  selectCaseTask,
  selectLead,
  selectLeadTask,
  showModal,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
  editLeadTask,
  editAccountTask,
  editCaseFile,
  editCaseTask,
  fetchRecords,
} from "actions";
import {
  loadingRecordsSelector,
  recordsSelector,
  caseRecordsSelector,
  accountRecordsSelector,
  caseRequirementRecordsSelector,
  leadRecordsSelector,
  storedRecordsFiltersSelector,
  storedCaseRecordsFiltersSelector,
  storedCaseRequirementRecordsFiltersSelector,
  storedAccountRecordsFiltersSelector,
  storedLeadRecordsFiltersSelector,
} from "reducers";
import IconLink from "components/IconLink";
import Table, { currencyCell, dateCell, dateOverdueCell, datetimeCell, shortNotesCell } from "components/table";
import { ACCOUNT_TASK_MODAL, CASE_FILE_MODAL, CASE_TASK_MODAL, LEAD_TASK_MODAL } from "components/modals";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "./constants";
import {
  CASE_DOCUMENT_STATUS_CHOICES_MAP,
  caseDocumentStatusChoices,
  TASKS_STATUS_CHOICES_MAP,
  tasksStatusChoices,
} from "../../constants";
import ReactSelect from "react-select";
import * as notifications from "../../notifications";

const RecordsTable = ({ type = TaskTab.ALL, showModal, recordsLoading, ...props }) => {
  const editMethods = {
    1: props.editCaseFile,
    2: props.editAccountTask,
    3: props.editLeadTask,
    4: props.editCaseTask,
  };

  const MAP = {
    [TaskTab.ALL]: props.recordsFilters,
    [TaskTab.CASE]: props.caseRecordsFilters,
    [TaskTab.CASE_REQUIREMENT]: props.caseRequirementRecordsFilters,
    [TaskTab.ACCOUNT]: props.accountRecordsFilters,
    [TaskTab.LEAD]: props.leadRecordsFilters,
  };
  let storedRecordsFilters = MAP[type];

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const cd = row.original.case_document;
        const objType = row.original.type;
        if (objType === 1) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={async () => {
                props.clearActiveCaseFile();
                props.selectActiveRecordId(row.original.id);
                let data = await props.fetchCaseFilesByCaseDocumentId(cd);
                if (data.case) {
                  props.fetchInsured(data.case);
                  props.fetchOwners(data.case);
                  props.fetchBeneficiaries(data.case);
                }
                showModal(CASE_FILE_MODAL);
              }}
            />
          );
        } else if (objType === 2) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                const record = row.original;
                const task = row.original.task_obj;
                props.selectAccount(task.account);
                props.selectActiveRecordId(row.original.id);
                props.selectAccountTask({
                  ...task,
                  created_by: record.record_creator,
                  last_edited_by_name: record.last_editor,
                  last_edit_date: record.last_edit_date,
                  date_created: record.date_created,
                });
                showModal(ACCOUNT_TASK_MODAL);
              }}
            />
          );
        } else if (objType === 3) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                const record = row.original;
                const task = row.original.task_obj;
                props.selectActiveRecordId(row.original.id);
                props.selectLead(row.original.task_obj?.lead_id);
                props.selectLeadTask({
                  ...task,
                  created_by: record.record_creator,
                  last_edited_by_name: record.last_editor,
                  last_edit_date: record.last_edit_date,
                  date_created: record.date_created,
                });
                showModal(LEAD_TASK_MODAL);
              }}
            />
          );
        } else if (objType === 4) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectActiveRecordId(row.original.id);
                props.selectCaseTask(row.original);
                showModal(CASE_TASK_MODAL);
              }}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Record ID",
      accessor: "record_id",
      className: "wrap-text",
      Cell: ({ row }) => {
        const objType = row.original.type;
        const caseId = row.original.case_id;
        const accountId = row.original.task_obj && row.original.task_obj.account;
        if (objType === 1 || objType === 4) {
          let redirectUrl = "files";
          if (objType === 4) redirectUrl = "tasks";
          return (
            <div className="text-wrap">
              <a href={`/cases/${caseId}/${redirectUrl}/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else if (objType === 2) {
          return (
            <div className="text-wrap">
              <a href={`/accounts/${accountId}/information/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else if (objType === 3) {
          return (
            <div className="text-wrap">
              <a href={`/leads/${row.original.task_obj?.lead_id}/overview/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
  ];
  if (type === TaskTab.ALL) {
    columns.push({
      Header: "Task Record Type",
      accessor: "record_type",
      Cell: ({ row }) => {
        if (row.original?.record_type === "Case Task") {
          return "Case";
        } else {
          return row.original?.record_type ? row.original.record_type : "";
        }
      },
    });
  }
  columns.push({
    Header: "Record Name",
    accessor: "record_name",
    className: "wrap-text",
  });
  columns.push({
    Header: "Task Status",
    accessor: "status",
    Cell: ({ row }) => {
      const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          minHeight: "36px",
          boxShadow: state.isFocused ? null : null,
          width: "200px",
        }),

        menu: (provided, state) => ({
          ...provided,
          width: "240px",
        }),
      };

      let initialValue = {};
      let statusChoices = tasksStatusChoices;
      let editMethod = null;
      let taskId = null;

      // Task Status is stored in different places depending on the type of task
      // Requirements have a long list of possible status
      // The other 3 types of tasks have 3 possible status: Pending, Completed, Waived
      const objType = row.original.type;
      editMethod = editMethods[objType];
      if (objType === 1) {
        // Case Requirement
        initialValue = {
          value: row.original.status,
          label: CASE_DOCUMENT_STATUS_CHOICES_MAP[row.original.status],
        };
        statusChoices = caseDocumentStatusChoices;
        taskId = row.original.case_document;
      } else {
        initialValue = {
          value: row.original.task_obj.status,
          label: TASKS_STATUS_CHOICES_MAP[row.original.task_obj.status],
        };
        statusChoices = tasksStatusChoices;
        taskId = row.original.task_obj.id;
      }

      let [statusValue, setStatusValue] = useState(initialValue);

      return (
        <ReactSelect
          menuPortalTarget={document.body}
          maxMenuHeight={200}
          name={"status"}
          options={statusChoices}
          onChange={async e => {
            setStatusValue({ value: e.value, label: e.label });
            await editMethod(taskId, { status: e.value });

            // refresh tasks
            const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
            await props.fetchTasks({ ...storedRecordsFilters }, fetch_action);
            notifications.clearAll();
            notifications.success("Task saved successfully");
          }}
          value={statusValue}
          className={`select`}
          menuPlacement="auto"
          styles={customStyles}
          isDisabled={false}
          placeholder="Select Status"
        />
      );
    },
  });

  if (type === TaskTab.CASE || type === TaskTab.CASE_REQUIREMENT) {
    columns.push({
      Header: "Face Amount",
      accessor: "face_amount",
      className: "wrap-text",
      Cell: currencyCell,
    });
    columns.push({
      Header: "Carrier Name",
      accessor: "carrier_name",
      className: "wrap-text",
    });
  }

  columns.push({
    Header: "Task Type",
    accessor: "task_type",
    className: "wrap-text",
  });
  columns.push({
    Header: "Task Notes",
    accessor: "notes",
    Cell: shortNotesCell,
  });
  columns.push({
    Header: "Task Owner",
    accessor: "record_owner",
    className: "wrap-text",
  });

  if (type === TaskTab.ACCOUNT) {
    columns.push({
      Header: "Work Phone",
      accessor: "work_phone",
    });
    columns.push({
      Header: "Cell Phone",
      accessor: "cell_phone",
    });
    columns.push({
      Header: "Other Phone",
      accessor: "other_phone",
    });
    columns.push({
      Header: "Work Email",
      accessor: "work_email",
    });
    columns.push({
      Header: "Other Email",
      accessor: "other_email",
    });
  } else if (type === TaskTab.LEAD) {
    columns.push({
      Header: "Phone Number",
      accessor: "phone_number",
    });
    columns.push({
      Header: "Other Phone",
      accessor: "other_phone",
    });
    columns.push({
      Header: "Email",
      accessor: "email",
    });
    columns.push({
      Header: "Other Email",
      accessor: "other_email",
    });
  }

  columns.push({
    Header: "Create Date",
    accessor: "date_created",
    Cell: dateCell,
  });
  columns.push({
    Header: "Last Edit Date",
    accessor: "last_edit_date",
    Cell: datetimeCell,
  });
  columns.push({
    Header: "Due Date",
    accessor: "due_date",
    Cell: dateOverdueCell,
  });

  if (type === TaskTab.CASE) {
    columns.push({
      Header: "Last Cases Status Sent",
      accessor: "last_sent_case",
      Cell: datetimeCell,
    });
  }
  let tableRecords = "";
  switch (type) {
    case TaskTab.ALL:
      tableRecords = props.records;
      break;
    case TaskTab.CASE:
      tableRecords = props.caseRecords;
      break;
    case TaskTab.CASE_REQUIREMENT:
      tableRecords = props.caseRequirements;
      break;
    case TaskTab.ACCOUNT:
      tableRecords = props.accountRecords;
      break;
    case TaskTab.LEAD:
      tableRecords = props.leadRecords;
      break;
  }
  if (tableRecords === "") return null;

  return (
    <Table
      columns={columns}
      data={tableRecords}
      emptyMessage={recordsLoading ? "Loading data..." : "No data"}
      initialPageSize={100}
      defaultPagination={false}
    />
  );
};

const mapStateToProps = state => {
  const records = recordsSelector(state);
  const caseRecords = caseRecordsSelector(state);
  const accountRecords = accountRecordsSelector(state);
  const leadRecords = leadRecordsSelector(state);
  const caseRequirements = caseRequirementRecordsSelector(state);
  return {
    records,
    caseRecords,
    caseRequirements,
    accountRecords,
    leadRecords,
    recordsLoading: loadingRecordsSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    caseRecordsFilters: storedCaseRecordsFiltersSelector(state),
    caseRequirementRecordsFilters: storedCaseRequirementRecordsFiltersSelector(state),
    accountRecordsFilters: storedAccountRecordsFiltersSelector(state),
    leadRecordsFilters: storedLeadRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  clearActiveCaseFile,
  fetchCaseFilesByCaseDocumentId,
  editCaseWithoutDispatch,
  selectAccount,
  selectAccountTask,
  selectActiveRecordId,
  selectLeadTask,
  selectCaseTask,
  selectLead,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
  editCaseFile,
  editAccountTask,
  editLeadTask,
  editCaseTask,
  fetchTasks: fetchRecords,
})(RecordsTable);
