import React from "react";
import { connect } from "react-redux";
import { fetchStates, selectState, showModal } from "actions";
import { activeAgencySelector, statesSelector } from "reducers";
import Table from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_STATE_MODAL } from "components/modals";
import { PERMISSIONS } from "../../constants";
import { isAuthorized } from "../../utils";
import { loadingStatesSelector } from "../../reducers/life_settlement/loading";

const StatesTable = ({ states, statesLoading, activeAgency, ...props }) => {
  React.useEffect(() => {
    props.fetchStates();
  }, []);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/states/${caseId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete State"
                onClick={() => props.showModal(DELETE_STATE_MODAL, { id: caseId })}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "State Name",
      accessor: "name",
    },
    {
      Header: "State Code",
      accessor: "code",
    },
  ];

  return (
    <Table
      columns={columns}
      data={states}
      emptyMessage={statesLoading ? "Loading States data..." : "Select a State"}
      defaultSort={true}
      showPaginationResults={true}
      initialPageSize={100}
      defaultPagination={true}
      sortBy={[{ id: "name", desc: false }]}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const states = statesSelector(state);
  const roles = state.auth.activeAgencyRoles;
  return {
    states: states,
    activeAgency: activeAgencySelector(state),
    statesLoading: loadingStatesSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  fetchStates,
  selectState,
  showModal,
})(StatesTable);
