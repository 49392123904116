import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import StateDetailForm from "./StateDetailForm";
import { activeStateSelector } from "../../reducers";
import { setActiveTab } from "actions";

const StateDetail = ({ stateInfo, ...props }) => {
  React.useEffect(() => {
    props.setActiveTab("state-detail-overview");
  }, []);
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <StateDetailForm stateInfo={stateInfo} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stateInfo: activeStateSelector(state),
  };
};

export default connect(mapStateToProps, { setActiveTab })(StateDetail);
