import React from "react";

import Table from "components/table";
import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import {
  DELETE_STATE_BROKER_FILE_MODAL,
  DELETE_STATE_PROVIDER_FILE_MODAL,
  STATE_BROKER_FILE_MODAL,
  STATE_PROVIDER_FILE_MODAL,
} from "components/modals";
import { connect } from "react-redux";
import { downloadFile, selectStateProviderFile, showModal } from "actions";
import IconLink from "../../components/IconLink";
import { selectStateBrokerFile } from "reducers/life_settlement/management/states";
import { isAuthorized } from "../../utils";
import { PERMISSIONS } from "../../constants";

const StateFilesTable = ({ stateInfo, type = "BROKER", hasAdminPermission, ...props }) => {
  if (!stateInfo) {
    return null;
  }

  let deleteModalActionType;
  if (type === "BROKER") {
    deleteModalActionType = DELETE_STATE_BROKER_FILE_MODAL;
  } else if (type === "PROVIDER") {
    deleteModalActionType = DELETE_STATE_PROVIDER_FILE_MODAL;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const fileId = row.original.id;
        const file = row.original.url;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                if (type === "BROKER") {
                  props.selectStateBrokerFile(row.original);
                  props.showModal(STATE_BROKER_FILE_MODAL, {
                    id: fileId,
                  });
                } else if (type === "PROVIDER") {
                  props.selectStateProviderFile(row.original);
                  props.showModal(STATE_PROVIDER_FILE_MODAL, {
                    id: fileId,
                  });
                }
              }}
            />
            {hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon btn--red" }}
                tooltip="Delete"
                onClick={() => {
                  props.showModal(deleteModalActionType, {
                    id: fileId,
                  });
                }}
              />
            )}
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/life_settlement/api/state_broker_file/${fileId}/download_file/`;
                    props.downloadFile(url, row.original.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "File Title",
      accessor: "title",
    },
    {
      Header: "Document Type",
      accessor: "document_type_display",
    },
    {
      Header: "File Status",
      accessor: "status_display",
    },
    {
      Header: "File Type",
      accessor: "file_type",
    },
    {
      Header: "File Size",
      accessor: "size",
    },
    {
      Header: "Date Uploaded",
      accessor: "date_uploaded",
    },
  ];

  return (
    <Table
      columns={columns}
      data={type === "BROKER" ? stateInfo.broker_files : stateInfo.provider_files}
      showPaginationResults={false}
      emptyMessage={`No ${type === "BROKER" ? "Broker" : "Provider"} Files`}
    />
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  showModal,
  downloadFile,
  selectStateBrokerFile,
  selectStateProviderFile,
})(StateFilesTable);
