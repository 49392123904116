import React from "react";
import { connect, useDispatch } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_SALES_REP_LEAD_EMAIL_MODAL } from "components/modals";
import { EditorField, FormCollapsible, SubmitButton, TextInput } from "../../../components/forms";
import { createLeadTask, fetchEmailTemplates, hideModal, sendEmailNotification } from "../../../actions";
import * as notifications from "../../../notifications";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { activeLeadSelector, emailTemplateSelector, managementChoicesSelector } from "../../../reducers";
import { currencyCellMoneyDisplay, findObjectByPropertyValue, getLabel } from "../../../utils";
import { getServerUrl } from "../../../apis/advisorhq";
import { LEAD_ROLES_CHOICES, LEAD_SOURCES_CHOICES } from "../constants";
import { PRODUCT_TYPES } from "../../../constants";

const SendSalesRepLeadEmailModal = props => {
  React.useEffect(() => {
    props.fetchEmailTemplates();
  }, []);

  const dispatch = useDispatch();
  const selectedSalesRepId = props.sales_representative_id;
  const salesRepData = props.salesRepChoices.find(salesRep => salesRep.value === selectedSalesRepId);
  let _to;
  let salesRepName;
  const templateName = "New Customer Lead Notification";
  if (salesRepData?.email) {
    let salesRepNameSplit = salesRepData.label.split(",");
    salesRepName = `${salesRepNameSplit[1]} ${salesRepNameSplit[0]}`.trim();
    _to = `${salesRepName} <${salesRepData.email}>`;
  }

  let targetEmailTemplate = findObjectByPropertyValue(props.emailTemplates, "name", templateName);
  let subject = "";
  let body = "";
  if (targetEmailTemplate) {
    subject = targetEmailTemplate.subject.replace("{Lead ID}", props.leadInfo.system_id);
    body = targetEmailTemplate.html;
    // Replace all tags in the body
    const leadUrl = `${getServerUrl()}/leads/${props.leadInfo.id}/overview/`;
    let config = {
      "{Sales Rep Name}": salesRepName,
      "{Lead ID}": props.leadInfo.system_id,
      "{Lead URL}": leadUrl,
      "{Lead Source}": props.leadInfo.source ? getLabel(LEAD_SOURCES_CHOICES, props.leadInfo.source) : "",
      "{Lead Score}": props.leadInfo.score,
      "{Lead First Name}": props.leadInfo.first_name,
      "{Lead Name}": props.leadInfo.first_name,
      "{Lead Phone}": props.leadInfo.phone,
      "{Lead Email}": props.leadInfo.email,
      "{Lead State}": props.leadInfo.state,
      "{Lead Role}": props.leadInfo.role ? getLabel(LEAD_ROLES_CHOICES, props.leadInfo.role) : "",
      "{Lead Carrier}": props.leadInfo.carrier_name,
      "{Lead Policy Type}": props.leadInfo.policy_type ? PRODUCT_TYPES[props.leadInfo.policy_type] : "",
      "{Lead Face Amount}": props.leadInfo.face_amount ? currencyCellMoneyDisplay(props.leadInfo.face_amount) : "",
      "{Lead Insured Age}": props.leadInfo.insured_1_age,
      "{Lead Insured Gender}": props.leadInfo.insured_1_gender,
      "{Lead Insured Health}": props.leadInfo.insured_1_health,
      "{Lead Insured Notes}": props.leadInfo.notes,
    };
    Object.entries(config).forEach(([key, value]) => {
      body = body.replace(key, value);
    });
  }

  return (
    <FullPageModal modalType={SEND_SALES_REP_LEAD_EMAIL_MODAL} title={`Send Sales Rep Lead Email`}>
      <Formik
        enableReinitialize
        initialValues={{
          _to,
          subject,
          body,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const response = await props.sendEmailNotification(values);
          dispatch(hideModal(SEND_SALES_REP_LEAD_EMAIL_MODAL));
          if (response && response.status === 500) {
            notifications.error("Error sending email");
          } else {
            notifications.clearAll();
            notifications.success("Email sent");
            dispatch(hideModal(SEND_SALES_REP_LEAD_EMAIL_MODAL));

            /* Create Lead Task */
            await props.createLeadTask({
              type: 6, // Follow-Up
              status: 1, // Pending
              owner: selectedSalesRepId,
              due_date: new Date(),
            });
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <>
              <FormCollapsible onSubmit={handleSubmit} title="Send Email">
                <Form.Row>
                  <TextInput label="To" name="_to" as="textarea" rows={2} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="CC" name="cc" placeholder="Enter CC Email(s)" />
                  <TextInput label="BCC" name="bcc" placeholder="Enter BCC Email(s)" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Subject" name="subject" />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Email Template" name="body" />
                </Form.Row>
                <SubmitButton defaultText={"Send"} />
              </FormCollapsible>
            </>
          );
        }}
      </Formik>
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const emailTemplates = emailTemplateSelector(state);
  const choices = managementChoicesSelector(state);
  const salesRepChoices = choices.case_sales_representatives.getChoices();
  return {
    emailTemplates,
    salesRepChoices,
    leadInfo: activeLeadSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchEmailTemplates,
  sendEmailNotification,
  createLeadTask,
})(SendSalesRepLeadEmailModal);
