import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  editFunderEntity,
  editInsuredInfo,
  fetchFunderEntities,
  selectSecurityIntermediary,
  selectTradingDocument,
  showModal,
  unsetShouldSave,
} from "actions";
import {
  activeFunderEntityIdSelector,
  activeFunderEntitySelector,
  activeFunderSelector,
  managementChoicesSelector,
  shouldSaveSelector,
} from "reducers";
import { TextInput, SubmitButton, EditorField, Select } from "components/forms";
import * as notifications from "../../notifications";
import { makeChoices } from "./utils";
import BooleanSelect from "../../components/forms/BooleanSelect";
import Collapsible from "../../components/Collapsible";
import { ButtonCreate } from "../../components/buttons";
import { RecordsTable } from "../../components/table";
import { SECURITY_INTERMEDIARY_MODAL, TRADING_DOCUMENT_MODAL } from "../../components/modals";
import SecuritiesIntermediaryTable from "./SecurityIntermediaryTable";
import TradingDocumentTable from "./TradingDocumentTable";
import { entityTypeChoices } from "../../constants";
import { State } from "country-state-city";
import { countriesChoices, funderEntityUsStatesChoices } from "../../options";

const FunderEntityForm = ({ funderEntityInfo, ...props }) => {
  if (!funderEntityInfo) {
    return null;
  }

  let {
    name,
    domicile,
    type,
    tin_number,
    status,
    street_address,
    city,
    country,
    state,
    zipcode,
    signor_name,
    signor_title,
    signor_email,
    escrow_agents,
    attn_name,
    attn_phone,
    attn_fax,
    attn_email,
    attn_street_address,
    attn_city,
    attn_country,
    attn_state,
    attn_zipcode,
    approved_trading_document,
    le_reps_and_warranties,
    docusign_accepted,
    servicers,
    notes,
  } = funderEntityInfo;

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (funderEntityInfo.country) {
      setStateChoices(getStateChoices(funderEntityInfo.country));
    }
  }, [country]);

  React.useEffect(() => {
    if (funderEntityInfo.attn_country) {
      setAttnStateChoices(getStateChoices(funderEntityInfo.attn_country));
    }
  }, [attn_country]);

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  let [commaSeparatedEscrowAgents, setCommaSeparatedEscrowAgents] = React.useState();

  const handleEscrowAgentChange = selectedOptions => {
    if (selectedOptions) {
      setCommaSeparatedEscrowAgents(selectedOptions.map(o => o.value).join(","));
    } else {
      setCommaSeparatedEscrowAgents("");
    }
  };

  let escrow_agents_initial = [];

  let escrowAgentChoices = props.escrowAgentChoices.getChoices();
  if (funderEntityInfo.escrow_agents) {
    let escrow_agents_list = funderEntityInfo.escrow_agents.split(",");
    for (let escrow_agent_id of escrow_agents_list) {
      let data = escrowAgentChoices.find(e => e.value === parseInt(escrow_agent_id));
      if (data) {
        escrow_agents_initial.push({ value: data.value, label: data.label });
      }
    }
  }

  let [formattedServicers, setFormattedServicers] = React.useState();
  let initial_servicers = [];

  let servicerChoices = props.servicerChoices.getChoices();
  if (funderEntityInfo.servicers) {
    let servicers_list = funderEntityInfo.servicers.split(",");
    for (let servicer_id of servicers_list) {
      let data = servicerChoices.find(e => e.value === parseInt(servicer_id));
      if (data) {
        initial_servicers.push({ value: data.value, label: data.label });
      }
    }
  }
  const handleServicerChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedServicers(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedServicers("");
    }
  };

  let [stateChoices, setStateChoices] = React.useState([]);
  let [attnStateChoices, setAttnStateChoices] = React.useState([]);

  const getStateChoices = countryCode => {
    let states_list;
    if (countryCode === "US") {
      states_list = funderEntityUsStatesChoices.getChoices();
    } else {
      const states = State.getStatesOfCountry(countryCode) || [];
      states_list = states.map(state => ({
        value: state.name,
        label: state.name,
      }));
    }
    const additional_choices = [
      { value: "3R", label: "Tertiary" },
      { value: "UK", label: "Unknown" },
    ];
    return [...states_list, ...additional_choices];
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name,
          domicile,
          type,
          tin_number,
          status,
          street_address,
          city,
          state,
          country,
          zipcode,
          signor_name,
          signor_title,
          signor_email,
          escrow_agents,
          attn_name,
          attn_phone,
          attn_fax,
          attn_email,
          attn_street_address,
          attn_city,
          attn_country,
          attn_state,
          attn_zipcode,
          approved_trading_document,
          le_reps_and_warranties,
          docusign_accepted,
          servicers,
          notes,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            values.escrow_agents = commaSeparatedEscrowAgents;
            values.servicers = formattedServicers;
            await props.editFunderEntity(props.activeFunderEntityId, values);
            await props.fetchFunderEntities(props.funderInfo.id);
            notifications.success("Funder Entity saved successfully");
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing Funder Entity");
          }
          props.unsetShouldSave();
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
              <Collapsible title="Entity Information">
                <Form.Row>
                  <TextInput label="Funder Entity Name" name="name" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Entity Domicile" name="domicile" />
                  <Select
                    label="Entity Type"
                    name="type"
                    placeholder="Select Entity Type"
                    options={entityTypeChoices}
                  />
                  <TextInput label="Entity TIN / FEIN" name="tin_number" />
                  <Select
                    name="status"
                    label="Entity Status"
                    placeholder={"Select Status"}
                    options={props.statusChoices}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" />
                  <TextInput label="City" name="city" />
                  <Select
                    label="Country"
                    name="country"
                    options={countriesChoices}
                    onChange={option => {
                      let value;
                      value = option === null ? "" : option.value;
                      setFieldValue("country", value);
                      setFieldValue("state", "");
                      setStateChoices(getStateChoices(value));
                    }}
                    md={2}
                  />
                  <Select label="State" name="state" options={stateChoices} md={2} />
                  <TextInput label="Zip Code" name="zipcode" md={2} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Signor Name" name="signor_name" />
                  <TextInput label="Signor Title" name="signor_title" />
                  <TextInput label="Signor Email" name="signor_email" lowercase={true} />
                  <Select
                    label="Escrow Agent(s)"
                    name="escrow_agents"
                    placeholder={"Select Escrow Agents"}
                    isMulti={true}
                    onChange={handleEscrowAgentChange}
                    options={props.escrowAgentChoices.getChoices()}
                    defaultValue={escrow_agents_initial}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Attn Name" name="attn_name" />
                  <TextInput label="Attn Phone" name="attn_phone" />
                  <TextInput label="Attn Fax" name="attn_fax" />
                  <TextInput label="Attn Email" lowercase={true} name="attn_email" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Attn Street Address" name="attn_street_address" />
                  <TextInput label="Attn City" name="attn_city" />
                  <Select
                    label="Attn Country"
                    name="attn_country"
                    options={countriesChoices}
                    onChange={option => {
                      let value;
                      value = option === null ? "" : option.value;
                      setFieldValue("attn_country", value);
                      setFieldValue("attn_state", "");
                      setAttnStateChoices(getStateChoices(value));
                    }}
                    md={2}
                  />
                  <Select label="Attn State" name="attn_state" options={attnStateChoices} md={2} />
                  <TextInput label="Attn Zip Code" name="attn_zipcode" md={2} />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect
                    label="Approved Trading Documents"
                    name="approved_trading_document"
                    placeholder={"Approved Trading Documents?"}
                  />
                  <BooleanSelect
                    label="LE Reps & Warranties"
                    name="le_reps_and_warranties"
                    placeholder={"LE Reps & Warranties?"}
                  />
                  <BooleanSelect
                    label="DocuSign Accepted"
                    name="docusign_accepted"
                    placeholder={"DocuSign Accepted?"}
                  />
                  <Select
                    label="Servicer(s)"
                    name="servicers"
                    placeholder={"Select Servicer(s)"}
                    options={props.servicerChoices.getChoices()}
                    isMulti={true}
                    onChange={handleServicerChange}
                    defaultValue={initial_servicers}
                  />
                </Form.Row>
                <Form.Row style={{ marginLeft: 5, marginRight: 5 }}>
                  <EditorField label="Funder Entity Notes" name="notes" />
                </Form.Row>
              </Collapsible>
              <RecordsTable
                title="Securities Intermediary"
                actionButtons={[
                  <ButtonCreate
                    onClick={e => {
                      e.preventDefault();
                      props.selectSecurityIntermediary(null);
                      props.showModal(SECURITY_INTERMEDIARY_MODAL);
                    }}
                  >
                    Add SI
                  </ButtonCreate>,
                ]}
                Table={SecuritiesIntermediaryTable}
              />
              <RecordsTable
                title="Trading Documents"
                actionButtons={[
                  <ButtonCreate
                    onClick={e => {
                      e.preventDefault();
                      props.selectTradingDocument(null);
                      props.showModal(TRADING_DOCUMENT_MODAL);
                    }}
                  >
                    Add Trading Document
                  </ButtonCreate>,
                ]}
                Table={TradingDocumentTable}
              />
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const choices = managementChoicesSelector(state);
  const activeFunderEntityId = activeFunderEntityIdSelector(state);
  const funderEntityInfo = activeFunderEntitySelector(state);
  const statusChoices = makeChoices([
    "Active",
    "Fund Formation",
    "Intermittent",
    "On Hold",
    "Not Active",
    "Unknown",
    "In Run Off",
  ]);

  return {
    funderInfo: activeFunderSelector(state),
    activeFunderEntityId,
    funderEntityInfo,
    statusChoices,
    escrowAgentChoices: choices.escrow_agents,
    servicerChoices: choices.servicers,
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  editInsuredInfo,
  editFunderEntity,
  selectSecurityIntermediary,
  selectTradingDocument,
  showModal,
  unsetShouldSave,
  fetchFunderEntities,
})(FunderEntityForm);
